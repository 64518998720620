import React from "react";
import "./download.css";
import Play from "../../imgs/play.png";
import App from "../../imgs/store.png";

const Download = () => {
  return (
    <div className="download-contain">
      <div className="download">
        <div className="download-title">DOWNLOAD APP</div>

        <div className="download-flex">
          <div className="download-txt">
            Get our <span>Mobile App</span> on both App Store & Play Store.
          </div>
          <div className="download-wrap">
            <div className="playstore">
              <img src={Play} alt=""  />
              <div className="play-txt">
                <p className="play-body">GET IT ON</p>
                <p className="play-name">Google Play</p>
              </div>
            </div>
            <div className="playstore">
              <img src={App} alt=""  />
              <div className="play-txt">
                <p className="play-body">GET IT ON</p>
                <p className="play-name">App Store</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;

import React from "react";
import ServCard from "../../utils/serv";
import "./services.css";

const Services = () => {
  return (
    <div className="services" id="services">
      <div className="services-contain">
        <ServCard
          title="RESIDENTIAL SERVICE"
          body="We offer a range of fumigation services for residential properties,
          including single-family homes, apartments, and townhouses."
          bg="yellow"
          color="yel"
        />
        <ServCard
          title="FUMIGATION TREATMENT"
          body="We use a range of safe and effective fumigation treatments to eliminate pests, including bed bugs, cockroaches, termites, and rodents."
          bg="purple"
          color="pur"
        />
        <ServCard
          title="COMMERCIAL SERVICE"
          body="Our commercial fumigation services are designed to protect businesses and their customers from pest infestations."
          bg="green"
          color="gre"
        />
      </div>

      <div className="services-txt">
        <p className="services-title">OUR SERVICES</p>
        <p className="services-sub">
          Our fumigation services are designed to meet the unique needs of your
          property. We start with a thorough inspection to identify any pest
          infestations and determine the best course of action. Our team then
          uses safe and effective fumigation methods to eliminate pests and
          prevent future infestations. We work with you every step of the way to
          ensure your complete satisfaction. Our services are available for both
          residential and commercial properties, so contact us today to schedule
          your fumigation appointment.
        </p>
      </div>
    </div>
  );
};

export default Services;

import React, { useState } from "react";
import Logo from "../imgs/foomigate.svg";
import Open from "./eye.png";
import Closed from "./eye-1.png";
import "./delete.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Validate = () => {
  const [view, setView] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const changeView = () => {
    if (view === true) {
      setView(false);
    } else {
      setView(true);
      reset();
    }
  };

  const reset = () => {
    setTimeout(() => {
      setView(false);
    }, 500);
  };

  const getUser = (token) => {
    axios
      .get("http://64.226.77.253:5000/api/v1/Customers/getCustomerProfile", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        sessionStorage.setItem("customerId", res.data.data.id);
        setLoad(false);
        navigate("/delete");
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    await axios
      .post(
        "http://64.226.77.253:5000/api/v1/Accounts/loginCustomer",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully logged in",
          timer: 1500,
        });
        console.log(res.data.data);
        getUser(res.data.data.token);
        sessionStorage.setItem("token", res.data.data.token);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          Swal.fire({
            icon: "error",
            title: err.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Invalid Credentials",
          });
        }
        setLoad(false);
      });
  };

  return (
    <div className="del-body">
      <div className="logo-div">
        <img src={Logo} alt="" className="logo" />
      </div>
      <div className="val-form">
        <p className="val-head">Kindly fill in your details</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">
            Email Address
            <input
              type="email"
              name="email"
              placeholder="E.g . Noah@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="password">
            Password
            <div className="val-pass">
              <input
                type={`${view ? "text" : "password"}`}
                name="password"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="eye" onClick={changeView}>
                <img src={view ? Open : Closed} alt="" />
              </div>
            </div>
          </label>

          <button type="submit" className="val-btn">
            {load ? "Loading ... " : "Continue"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Validate;

import React, { useState } from "react";
import Logo from "../../imgs/foomigate.svg";
import Menu from "../../imgs/menu-btn.png";
import "./nav.css";
import { Link } from "react-router-dom";

const New = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`nav-contain ${open ? "isOpen" : ""}`}>
      <div className="nav">
        <Link to="/" className="logo-div">
          <img src={Logo} alt="" className="logo" />
        </Link>
        <div className="flex desktop">
          <Link to="/" className="nav-link">
            HOME
          </Link>
          <Link to="/#about" className="nav-link">
            ABOUT
          </Link>
          <Link to="/#services" className="nav-link">
            SERVICES
          </Link>
          <Link to="/#download"  className="nav-btn">
            Get started
          </Link>
        </div>
        <div className="mobile">
          {open ? (
            <p className="close" onClick={() => setOpen(false)}>
              X
            </p>
          ) : (
            <img
              src={Menu}
              alt=""
              className="menu-img"
              onClick={() => setOpen(true)}
            />
          )}
        </div>
      </div>

      {open ? (
        <div className="nav-drop mobile">
          <Link to="/" onClick={()=>setOpen(false)} className="nav-link">HOME</Link>
          <Link to="/#about" onClick={()=>setOpen(false)} className="nav-link" >
            ABOUT
          </Link>
          <Link to="/#services" onClick={()=>setOpen(false)} className="nav-link" >
            SERVICES
          </Link>
          <Link to="/#download" onClick={()=>setOpen(false)} className="nav-link">
            GET STARTED
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default New;

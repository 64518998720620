import React, { useState } from "react";
import "./treatment.css";
import Swal from "sweetalert2";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";

const Treatment = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error1, setError1] = useState("");
  const [test, setTest] = useState("");

  const [value, setValue] = useState("");

  const handleOnchange = (val) => {
    setValue(val);
    setTest(val);
    setFields({ ...fields, Pest: test });
  };

  const options = [
    { label: "Rodents", value: "rodents" },
    { label: "Arachnids/Insects", value: "insects" },
    { label: "Snake", value: "snake" },
    { label: "Others", value: "others" },
  ];

  const [fields, setFields] = useState({
    Type: "",
    Room: "",
    Pest: "",
    Mode: "",
    Schedule: "",
    Email: email,
    Phone: phoneNumber,
  });

  function handleEmailChange(event) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setFields({ ...fields, Email: inputEmail });
    if (!inputEmail.match(emailPattern)) {
      setError("Please enter a valid email address");
      if (inputEmail === "") {
        setError("");
      }
    } else {
      setError("");
    }
  }

  function handlePhoneNumberChange(event) {
    const phoneNumberPattern = /^(\+234|0)[789][01]\d{8}$/;
    const inputPhoneNumber = event.target.value;
    setPhoneNumber(inputPhoneNumber);
    setFields({ ...fields, Phone: inputPhoneNumber });
    if (!inputPhoneNumber.match(phoneNumberPattern)) {
      setError1("Please enter a valid Nigerian phone number");
      if (inputPhoneNumber === "") {
        setError1("");
      }
    } else {
      setError1("");
    }
  }

  const handleChange = (e) => {
    const input = e.target;
    setFields({
      ...fields,
      [input.name]: input.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(value)
    setLoading(true);
    const form = document.querySelector("form");
    const formData = new FormData(form);
    fetch(
      "https://script.google.com/macros/s/AKfycbyyI3u09Meew_NDX3jAoLEo7pXHt8VOIyQpF30TOCHSmB8QBdNi1gARc7gH_sYXbdiE/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        console.log(res);
        Swal.fire({
          icon: "success",
          title: "Request Placed Successfully",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "An error occured",
        });
        setLoading(false);
      });
  };

  return (
    <div className="treatment">
      <p className="treatment-title">CUSTOM TREATMENT</p>
      <div className="treatment-sub">
        <p className="treatment-body">
          We provide customized treatment plans to solve your unique pest
          problems. Our experts will inspect your property and develop a
          personalized plan using safe and effective products and techniques.
        </p>
        <p className="treatment-body">
          Do you need enterprise fumigation for your offices or complex
          building? Fill out the form:
        </p>
      </div>

      <form className="form" onSubmit={handleSubmit}>
        <div className="treatment-flex">
          <label htmlFor="Type">
            What type of building do you have?
            <input
              type="text"
              name="Type"
              value={fields.Type}
              onChange={(e) => handleChange(e)}
              placeholder="Bungalow"
              required
            />
          </label>
          <label htmlFor="Room">
            How many rooms does the building have?
            <input
              type="text"
              name="Room"
              value={fields.Room}
              onChange={(e) => handleChange(e)}
              placeholder="4 rooms"
              required
            />
          </label>
        </div>

        <div className="treatment-flex pull">
          <div>
            <label htmlFor="Pest">Type of Pest</label>
            <MultiSelect onChange={handleOnchange} options={options} />
            <input
              type="text"
              name="Pest"
              id="Pest"
              className="none"
              value={test}
              required
            />
          </div>
          <label htmlFor="Mode">
            Type of fumigation
            <select
              name="Mode"
              id="Mode"
              value={fields.Mode}
              onChange={(e) => handleChange(e)}
              required
            >
              <option value="" disabled selected>
                Select type of fumigation
              </option>
              <option value="water">Water-based Fumigation</option>
              <option value="smoke">Smoke & Water-based Fumigation</option>
              <option value="others">Others</option>
            </select>
          </label>
        </div>

        <div className="treatment-flex">
          <label htmlFor="Schedule">
            Fumigation Schedule
            <select
              name="Schedule"
              id="Schedule"
              value={fields.Schedule}
              onChange={(e) => handleChange(e)}
              required
            >
              <option value="" disabled selected>
                Select fumigation schedule
              </option>
              <option value="one-off">One-off Treatment</option>
              <option value="continuous">Continuous Treatement</option>
            </select>
          </label>
          <label htmlFor="Number">
            Contact number
            <input
              type="text"
              name="Number"
              placeholder="+234 706 786 4563"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
            {error1 && <div style={{ color: "red" }}>{error1}</div>}
          </label>
        </div>

        <label htmlFor="Email">
          Email Address
          <input
            type="text"
            name="Email"
            placeholder="Foomigate@gmail.com"
            value={email}
            onChange={handleEmailChange}
            required
          />
          {error && <div style={{ color: "red" }}>{error}</div>}
        </label>

        <button className="treatment-btn" type="submit">
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default Treatment;

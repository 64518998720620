import React from "react";
import "./footer.css";
import Logo from "../../imgs/foomigate1.svg";
import Social1 from "../../imgs/Twitter.png";
import Social2 from "../../imgs/Instagram.png";
import Social3 from "../../imgs/Facebook.png";
import { Link } from "react-router-dom";

const Foot = () => {
  return (
    <div className="footer">
      <div className="up-footer">
        <div className="footer-main">
          <Link to="/" className="logo-div">
            <img src={Logo} alt="" className="foot-logo" />
          </Link>
          <p className="footer-head">Africa’s No. 1 Fumigation Service</p>
          <div className="socials">
            <img src={Social1} alt="" />
            <a
              href="https://instagram.com/foomigeit?igshid=MzRIODBiNWFIZA=="
              rel="noreferrer"
              target="_blank"
            >
              <img src={Social2} alt="" />
            </a>
            <img src={Social3} alt="" />
          </div>
          <p className="copyright">© Copyright 2023 All Rights Reserved</p>
        </div>

        <div className="footer-nav">
          <div className="footer-list">
            <p className="footer-title">Quick links</p>
            <Link to="/#about" className="footer-link">
              About
            </Link>
            <Link to="/#services" className="footer-link">
              Services
            </Link>
            <Link to="/download" className="footer-link">
              Get Started
            </Link>
            <Link className="footer-link" to="/#testimonial">
              Testimonial
            </Link>
          </div>

          <div className="footer-list">
            <p className="footer-title">Address</p>
            <p className="footer-link reduced">
              33 Association Avenue, Shangisha, Lagos Nigeria.
            </p>
          </div>

          <div className="footer-list">
            <p className="footer-title">Contact</p>
            <p className="footer-link">+234 807 657 8975</p>
            <p className="footer-link">+234 806 642 7303</p>
          </div>
        </div>
      </div>
      <div className="down-footer">
        <Link to="/terms" className="footer-end">
          Terms & Condition
        </Link>
        <Link to="/privacy" className="footer-end">
          Privacy policies
        </Link>
      </div>
    </div>
  );
};

export default Foot;

import React from "react";
import "./about.css";
import Img1 from "../../imgs/about1.png";
import Img2 from "../../imgs/about2.png";
import Circle1 from "../../imgs/circle1.png";
import Circle2 from "../../imgs/circle2.png";
import Small1 from "../../imgs/small1.png";
import Small2 from "../../imgs/small2.png";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="about-txt">
        <p className="about-title">ABOUT US</p>
        <p className="about-header">
          At <span>Foomigeit,</span>
        </p>
        <p className="about-sub">
          <span>We help you Protect your home or business</span> from unwanted
          pests with our professional fumigation services. Our experienced team
          uses safe and effective methods to eliminate a wide range of pests,
          including termites, bed bugs, roaches, and more. With our
          comprehensive solutions and personalized approach, you can trust us to
          keep your property pest-free.
        </p>
      </div>

      <div className="about-section">
        <img src={Img1} alt="" className="about-img1"   />
        <img src={Small1} alt="" className="about-img2"   />
        <img src={Circle1} alt="" className="about-img3"   />
        <img src={Img2} alt="" className="about-img4"   />
        <img src={Small2} alt="" className="about-img5"   />
        <img src={Circle2} alt="" className="about-img6"   />
      </div>
    </div>
  );
};

export default About;

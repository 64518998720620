import React from "react";
import "./App.css";
import About from "./components/about/about";
import Download from "./components/download/download";
import Hero from "./components/hero/hero";
import Services from "./components/services/services";
import Steps from "./components/steps/steps";
import Testimonial from "./components/testimonial/testimonial";
import Treatment from "./components/treatment/treatment";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Priv from "./components/priv/priv";
import Terms from "./components/terms/terms";
import ScrollToTop from "./utils/scroll";
import New from "./components/navbar/new";
import Foot from "./components/footer/foot";
import { useEffect } from "react";
import Delete from "./delete/delete";
import Validate from "./delete/validate";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <New />
      <Hero />
      <About />
      <Services />
      <Steps />
      <div id="download">
        <Download />
      </div>
      <Treatment />
      <Testimonial />
      <Foot />
    </>
  );
};

const Privacy = () => {
  return (
    <div>
      <New />
      <Priv />
      <Foot />
    </div>
  );
};

const Term = () => {
  return (
    <div>
      <New />
      <Terms />
      <Foot />
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Term />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/validate" element={<Validate />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

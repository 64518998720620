import React from "react";
// import Button from "../../utils/btn";
import Image from "../../imgs/hero-img.png";
import "./hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-txt">
        <div className="hero-header">
          Professional <span>Fumigation</span> Service for a{" "}
          <span>Pest-Free</span> Environment.
        </div>
        <div className="hero-sub">
          Protect your home or business with our expert fumigation services. Our
          experienced professionals use advanced techniques and equipment to
          thoroughly and safely treat your space. Say goodbye to termites, bed
          bugs, and other pests and enjoy a pest-free environment.
        </div>
        {/* <Button /> */}
        <Link className="nav-btn" to='/#download'>
          Get started
        </Link>
      </div>
      <img src={Image} alt="" className="hero-img"  />
    </div>
  );
};

export default Hero;

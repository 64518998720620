import React from "react";
import "./testimonial.css";
import Star from "../../imgs/star.png";
import Half from "../../imgs/star_half.png";
import Img1 from "../../imgs/person1.png";
import Img2 from "../../imgs/person2.png";
import Img3 from "../../imgs/person3.png";
import Img4 from "../../imgs/person4.png";

const Testimonial = () => {
  return (
    <div className="testimonial" id="testimonial">
      <p className="testimonial-title">TESTIMONIAL</p>
      <p className="testimonial-sub">
        Here's what our satisfied customers have to say about our fumigation
        services:
      </p>

      <div className="testimonial-contain">
        <div className="testimonial-card">
          <div className="testimonial-star">
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Half} alt=""   />
          </div>
          <div className="testimonial-body">
            Professional and efficient! The team at ABC Fumigation did an
            amazing job getting rid of pests in our home. We highly recommend
            their services!
          </div>
          <div className="testimonial-author">
            <img src={Img1} alt=""   />
            <div className="testimonial-person">
              <p className="testimonial-person">Williams Briggs</p>
              <div className="testimonial-job">Banker</div>
            </div>
          </div>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-star">
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Half} alt=""   />
          </div>
          <div className="testimonial-body">
            The team was professional and knowledgeable, answered all my
            questions and left my home pest-free.
          </div>
          <div className="testimonial-author">
            <img src={Img2} alt=""   />
            <div className="testimonial-person">
              <p className="testimonial-person">Emeka Chinedu</p>
              <div className="testimonial-job">Business man</div>
            </div>
          </div>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-star">
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Half} alt=""   />
          </div>
          <div className="testimonial-body">
            I was impressed with their attention to detail and willingness to go
            the extra mile to ensure a job well done. I’ll highly recommend them
            to anyone
          </div>
          <div className="testimonial-author">
            <img src={Img3} alt=""   />
            <div className="testimonial-person">
              <p className="testimonial-person">Abdul Qudus</p>
              <div className="testimonial-job">Manager</div>
            </div>
          </div>
        </div>

        <div className="testimonial-card">
          <div className="testimonial-star">
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Star} alt=""   />
            <img src={Half} alt=""   />
          </div>
          <div className="testimonial-body">
            The fumigation services provided by foomigate were top-notch. They
            arrived on time, worked quickly and efficiently.
          </div>
          <div className="testimonial-author">
            <img src={Img4} alt=""   />
            <div className="testimonial-person">
              <p className="testimonial-person">Adeola Daniel</p>
              <div className="testimonial-job">Teacher</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
